import { useState } from "react";
import StockGroupModel from "./models/StockGroup";
import StockModel from "./models/Stock";
import "./index.css";

function App() {
  const [Portfolio, setPortfolio] = useState<StockModel[]>([]);
  async function fetchData() {
    const response = await fetch(
      `https://api.polygon.io/v2/aggs/grouped/locale/us/market/stocks/2023-04-14?adjusted=true&apiKey=${process.env.REACT_APP_POLYGON_API}`
    );
    if (response.ok) {
      filterStock(await response.json());
    }
  }
  async function filterStock(AllStocks: StockGroupModel) {
    const FullList: StockModel[] = AllStocks.results;
    var nvo: StockModel = FullList.find((stock) => {
      return stock.T === "NVO";
    })!;

    nvo.buyPrice = "135.93";
    nvo.move = (Number(nvo.c) - Number(nvo.buyPrice)).toFixed(2).toString();

    const voo: StockModel = FullList.find((stock) => {
      return stock.T === "VOO";
    })!;

    voo.buyPrice = "360.93";
    voo.move = (Number(voo.c) - Number(voo.buyPrice)).toFixed(2).toString();

    const ko: StockModel = FullList.find((stock) => {
      return stock.T === "KO";
    })!;
    ko.buyPrice = "63.93";
    ko.move = (Number(ko.c) - Number(ko.buyPrice)).toFixed(2).toString();

    const amzn: StockModel = FullList.find((stock) => {
      return stock.T === "AMZN";
    })!;
    amzn.buyPrice = "107";
    amzn.move = (Number(amzn.c) - Number(amzn.buyPrice)).toFixed(2).toString();

    const jnj: StockModel = FullList.find((stock) => {
      return stock.T === "JNJ";
    })!;
    jnj.buyPrice = "178.11";
    jnj.move = (Number(jnj.c) - Number(jnj.buyPrice)).toFixed(2).toString();

    const pfe: StockModel = FullList.find((stock) => {
      return stock.T === "PFE";
    })!;
    pfe.buyPrice = "51.93";
    pfe.move = (Number(pfe.c) - Number(pfe.buyPrice)).toFixed(2).toString();


    setPortfolio([nvo, voo, ko, amzn, jnj, pfe]);
  }

  return (
    <html className="mx-auto max-w-[1300px]">
      <body className="">
        <div className="text-center my-[100px]">
          <button
            className="bg-gray-50 rounded-md text-black p-2"
            onClick={() => fetchData()}
          >
            Fetch Data
          </button>
        </div>
        <table className="table-auto w-full text-center">
          <thead>
            <tr>
              <th>Ticker</th>
              <th>Closing Price</th>
              <th>Purchased Price</th>
              <th>Profit/Loss</th>
            </tr>
          </thead>
          <tbody>
            {Portfolio !== undefined ? (
              <>
                {Portfolio.map((e) => (
                  <tr>
                    <th>{e.T}</th>
                    <td>{e.c}</td>
                    <td>{e.buyPrice}</td>
                    <td>
                      {Number(e.move) > 0 ? (
                        <p style={{ color: "green" }}>{e.move}</p>
                      ) : (
                        <p style={{ color: "red" }}>{e.move}</p>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </body>
    </html>
  );
}

export default App;
